import Default from './default'

import AndroidChrome144 from '../../assets/favicon/android-chrome-144x144.png'
import AndroidChrome192 from '../../assets/favicon/android-chrome-192x192.png'
import AndroidChrome36 from '../../assets/favicon/android-chrome-36x36.png'
import AndroidChrome48 from '../../assets/favicon/android-chrome-48x48.png'
import AndroidChrome72 from '../../assets/favicon/android-chrome-72x72.png'
import AndroidChrome96 from '../../assets/favicon/android-chrome-96x96.png'
import AppleTouchIcon114 from '../../assets/favicon/apple-touch-icon-114x114.png'
import AppleTouchIcon120 from '../../assets/favicon/apple-touch-icon-120x120.png'
import AppleTouchIcon144 from '../../assets/favicon/apple-touch-icon-144x144.png'
import AppleTouchIcon152 from '../../assets/favicon/apple-touch-icon-152x152.png'
import AppleTouchIcon180 from '../../assets/favicon/apple-touch-icon-180x180.png'
import AppleTouchIcon57 from '../../assets/favicon/apple-touch-icon-57x57.png'
import AppleTouchIcon60 from '../../assets/favicon/apple-touch-icon-60x60.png'
import AppleTouchIcon72 from '../../assets/favicon/apple-touch-icon-72x72.png'
import AppleTouchIcon76 from '../../assets/favicon/apple-touch-icon-76x76.png'
// import AppleTouchIconPre from '../../assets/favicon/apple-touch-icon-precomposed.png'
// import AppleTouchIcon from '../../assets/favicon/apple-touch-icon.png'
import Favicon16 from '../../assets/favicon/favicon-16x16.png'
import Favicon32 from '../../assets/favicon/favicon-32x32.png'
import Favicon96 from '../../assets/favicon/favicon-96x96.png'
// import Favicon from '../../assets/favicon/favicon.ico'
// import MSTile144 from '../../assets/favicon/mstile-144x144.png'
// import MSTile150 from '../../assets/favicon/mstile-150x150.png'
// import MSTile310150 from '../../assets/favicon/mstile-310x150.png'
// import MSTile310 from '../../assets/favicon/mstile-310x310.png'
// import MSTile70 from '../../assets/favicon/mstile-70x70.png'
import SafariPinnedTab from '../../assets/favicon/safari-pinned-tab.svg'
const Manifest = '../../assets/favicon/manifest.json'

export const Favicons = [
  {rel: 'icon', sizes: '36x36', href: AndroidChrome36},
  {rel: 'icon', sizes: '48x48', href: AndroidChrome48},
  {rel: 'icon', sizes: '72x72', href: AndroidChrome72},
  {rel: 'icon', sizes: '96x96', href: AndroidChrome96},
  {rel: 'icon', sizes: '144x144', href: AndroidChrome144},
  {rel: 'icon', sizes: '192x192', href: AndroidChrome192},
  {rel: 'apple-touch-icon', sizes: '57x57', href: AppleTouchIcon57},
  {rel: 'apple-touch-icon', sizes: '60x60', href: AppleTouchIcon60},
  {rel: 'apple-touch-icon', sizes: '72x72', href: AppleTouchIcon72},
  {rel: 'apple-touch-icon', sizes: '76x76', href: AppleTouchIcon76},
  {rel: 'apple-touch-icon', sizes: '114x114', href: AppleTouchIcon114},
  {rel: 'apple-touch-icon', sizes: '120x120', href: AppleTouchIcon120},
  {rel: 'apple-touch-icon', sizes: '144x144', href: AppleTouchIcon144},
  {rel: 'apple-touch-icon', sizes: '152x152', href: AppleTouchIcon152},
  {rel: 'apple-touch-icon', sizes: '180x180', href: AppleTouchIcon180},
  {rel: 'icon', type: 'image/png', sizes: '16x16', href: Favicon16},
  {rel: 'icon', stype: 'image/png', izes: '32x32', href: Favicon32},
  {rel: 'icon', stype: 'image/png', izes: '16x16', href: Favicon96},
  {rel: 'manifest', href: Manifest},
  {rel: 'mask-icon', color: '#5bbad5', href: SafariPinnedTab},
]

export {Default}
export default Default
