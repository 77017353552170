import _ from 'lodash'
import {css} from 'glamor'

import {
  // ArvoRegularTtf,
  // ArvoRegularWoff,
  // ArvoRegularWoff2,
  // SpaceMonoRegularTtf,
  // SpaceMonoRegularWoff,
  // SpaceMonoRegularWoff2,
  GoodMonoRegular,
  MuliItalicTtf,
  MuliItalicWoff,
  MuliItalicWoff2,
  MuliLightTtf,
  MuliLightWoff,
  MuliLightWoff2,
  MuliLightItalicTtf,
  MuliLightItalicWoff,
  MuliLightItalicWoff2,
  MuliRegularTtf,
  MuliRegularWoff,
  MuliRegularWoff2,
  MuliBoldTtf,
  MuliBoldWoff,
  MuliBoldWoff2,
  MuliBoldItalicTtf,
  MuliBoldItalicWoff,
  MuliBoldItalicWoff2,
} from './fonts'
/*
 * Helper Functions
 */

/*
 * mediaMinWidth
 *  Takes a size query and constructs a media min-width query using that value
 *  @param size String
 */
export const mediaMinWidth = size => `@media only screen and (min-width: ${size}px)`

/*
 *  mediaMaxWidth
 *  Takes a size query and constructs a media max-width query using that value
 *  @param size String
 */
export const mediaMaxWidth = size => `@media only screen and (max-width: ${size}px)`

/*
 * getThemeStyles
 *  Given a component name and a custom props->styles function, merge the theme
 *  styles with dynamic styles from propStyles
 *  @param name String
 *  @param propStyles Function
 */
export const getThemeStyles = (name, propStyles) => {
  return props => {
    const themeStyles = (props.theme && props.theme[name]) || {}
    return {
      ...(_.isFunction(themeStyles) ? themeStyles() : themeStyles),
      ...(propStyles ? propStyles(props) : {}),
    }
  }
}

/*
 * mergeThemeStyles
 *  @param theme Object
 *  @param arrThemeKeys Array<String>
 *  @return style Object
 *
 */
export const mergeThemeStyles = (theme, arrThemeKeys) =>
  _.reduce(
    arrThemeKeys,
    (styles, key) => {
      const keyStyles = theme[key]
      return keyStyles ? {...styles, ...keyStyles} : styles
    },
    {}
  )

export const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}${alpha ? `, ${alpha}` : ''})`
}

/*
 * Responsive Breakpoints
 * Source: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
 */

export const xs = 0 // Only use for min-width
export const sm = 480
export const md = 768
export const lg = 992
export const xl = 1200

export const gutter = 16

/*
 * Colors
 */
export const colors = {
  white: '#ffffff',
  lightGrey: '#e8e8e8',
  darkGrey: '#515151',
  black: '#333333',
  orange: '#f7b757',
  red: '#a00a00',
}

export const colorsRGB = {
  white: '255, 255, 255',
  lightGrey: '232, 232, 232',
  darkGrey: '81, 81, 81',
  black: '51, 51, 51',
  orange: '247, 183, 87',
}

/*
 * Fonts
 */
export const fonts = {
  body: "'Muli', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
  title: "'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
  code: "'Good Mono', 'Menlo', 'Monaco', 'Courier New', 'monospace'",
}

// Arvo
// css.fontFace({
//   fontFamily: 'Arvo',
//   src: ` local('Arvo'), local('Arvo-Regular'),
//         url(${ArvoRegularWoff2}) format('woff2'),
//         url(${ArvoRegularWoff}) format('woff'),
//         url(${ArvoRegularTtf}) format('truetype')`,
//   fontWeight: 'normal',
//   fontStyle: 'normal',
// })

// Space Mono
css.fontFace({
  fontFamily: 'Good Mono',
  src: `url(${GoodMonoRegular}) format('opentype')`,
  fontWeight: 'normal',
  fontStyle: 'normal',
})

// Muli
css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli SemiBold'), local('Muli-SemiBold'),
        url(${MuliBoldWoff2}) format('woff2'),
        url(${MuliBoldWoff}) format('woff'),
        url(${MuliBoldTtf}) format('truetype')`,
  fontWeight: 600,
  fontStyle: 'normal',
})

css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli SemiBold Italic'), local('Muli-SemiBoldItalic'),
        url(${MuliBoldItalicWoff2}) format('woff2'),
        url(${MuliBoldItalicWoff}) format('woff'),
        url(${MuliBoldItalicTtf}) format('truetype')`,
  fontWeight: 600,
  fontStyle: 'italic',
})

css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli Italic'), local('Muli-Italic'),
        url(${MuliItalicWoff2}) format('woff2'),
        url(${MuliItalicWoff}) format('woff'),
        url(${MuliItalicTtf}) format('truetype')`,
  fontWeight: 'normal',
  fontStyle: 'italic',
})

css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli Regular'), local('Muli-Regular'),
        url(${MuliRegularWoff2}) format('woff2'),
        url(${MuliRegularWoff}) format('woff'),
        url(${MuliRegularTtf}) format('truetype')`,
  fontWeight: 'normal',
  fontStyle: 'normal',
})

css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli Light'), local('Muli-Light'),
        url(${MuliLightWoff2}) format('woff2'),
        url(${MuliLightWoff}) format('woff'),
        url(${MuliLightTtf}) format('truetype')`,
  fontWeight: 300,
  fontStyle: 'normal',
})

css.fontFace({
  fontFamily: 'Muli',
  src: ` local('Muli Light Italic'), local('Muli-LightItalic'),
        url(${MuliLightItalicWoff2}) format('woff2'),
        url(${MuliLightItalicWoff}) format('woff'),
        url(${MuliLightItalicTtf}) format('truetype')`,
  fontWeight: 300,
  fontStyle: 'italic',
})

/*
 * Global
 */
const htmlBody = {
  height: '100%',
  margin: 0,
  padding: 0,
  fontFamily: `${fonts.body} !important`,
  fontSize: '1rem !important', // override skeleton and normalize font-size settings
  fontWeight: 'normal',
  lineHeight: 1.5,
  [mediaMaxWidth('38rem')]: {
    fontSize: '1.1rem',
  },
}

css.global('html, body', htmlBody)

css.global('*', {
  WebkitBoxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  BoxSizing: 'border-box',
})

/*
 * Styles
 */

// Color is defined in theme.js
const h = {
  marginBottom: '1.75rem',
  fontFamily: fonts.title,
  fontWeight: 700,
  lineHeight: 1.25,
  letterSpacing: 'normal',
  textRendering: 'optimizeLegibility',
}

const hLight = {
  ...h,
  color: colors.black,
}

const hDark = {
  ...h,
  color: colors.orange,
}

const listParents = {
  marginTop: 0,
  marginBottom: '1rem',
  padding: 0,
}

const listChildren = {
  marginLeft: '1rem',
}

const italic = {}

const hoverLinkButton = {
  backgroundColor: `rgba(${colorsRGB.orange}, 0.3)`,
  transition: 'background-color 0.1s ease',
  outline: 'none',
}

const inputButton = {
  display: 'inline-block',
  height: 38,
  padding: '0 30px',
  color: colors.black,
  textAlign: 'center',
  fontSize: '0.8rem',
  fontWeight: 600,
  lineHeight: '38px',
  letterSpacing: '.1rem',
  textTransform: 'uppercase',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent',
  borderRadius: 4,
  border: '1px solid #bbb',
  cursor: 'pointer',
  boxSizing: 'border-box',
}
const inputButtonHover = {
  color: colors.black,
  // TODO: Theme
  borderColor: colors.orange,
  outline: 0,
}

const input = {
  height: 38,
  padding: '6px 10px' /* The 6px vertically centers text on FF, ignored by Webkit */,
  backgroundColor: 'transparent',
  // TODO: Theme
  border: `1px solid ${colors.lightGrey}`,
  fontSize: '0.8rem',
  borderRadius: 4,
  boxShadow: 'none',
  boxSizing: 'border-box',
}

const inputFocus = {
  // TODO: Theme
  border: `1px solid ${colors.orange}`,
  outline: 0,
}

/*
 * Theme Definitions
 */

export const common = {
  container: {
    position: 'static',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '50rem',
    marginTop: '2.5rem',
    marginBottom: '4rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: gutter,
    paddingRight: gutter,
  },
  body: {},
  h1: {
    ...h,
    fontSize: '2.2rem',
  },
  h2: {
    ...h,
    fontSize: '1.8rem',
    marginTop: '1.8rem',
  },
  h3: {
    ...h,
    fontsize: '1.6rem',
    marginTop: '2rem',
  },
  h4: {
    ...h,
    fontSize: '1.1rem',
    marginTop: '2rem',
  },
  h5: {
    ...h,
    fontSize: '1.1rem',
    marginTop: '2rem',
  },
  h6: {
    ...h,
    fontSize: '1.1rem',
    marginTop: '2rem',
  },
  p: {
    marginTop: 0,
    marginBottom: '1rem',
    fontSize: '1rem',
    lineHeight: '2rem',
  },
  span: {},
  strong: {
    color: 'inherit',
    fontWeight: 600,
  },
  i: italic,
  em: italic,
  ul: listParents,
  ol: listParents,
  dl: listParents,
  li: listChildren,
  hr: {
    position: 'relative',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    width: '100%',
  },
  a: {
    position: 'relative',
    textDecoration: 'none',
    borderBottom: '3px solid',
    '&:hover::before': {
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
    '&::before': {
      content: '""',
      visibility: 'hidden',
      position: 'absolute',
      width: '100%',
      height: 3,
      bottom: -3,
      left: 0,
      backgroundColor: colors.red,
      transform: 'scaleX(0)',
      transition: 'all 0.20s ease-in-out 0s',
    },
  },
  button: {
    ...inputButton,
    '&:hover': {...inputButtonHover},
    '&:focus': {...inputButtonHover},
  },
  input: {
    // Button Types
    '&[type="submit"]': {...inputButton},
    '&[type="reset"]': {...inputButton},
    '&[type="button"]': {...inputButton},
    // Button Hover/Focus
    '&[type="submit"]&:hover': {...inputButtonHover},
    '&[type="reset"]&:hover': {...inputButtonHover},
    '&[type="button"]&:hover': {...inputButtonHover},
    '&[type="submit"]&:focus': {...inputButtonHover},
    '&[type="reset"]&:focus': {...inputButtonHover},
    '&[type="button"]&:focus': {...inputButtonHover},
    // Input
    '&[type="email"]': {...input},
    '&[type="number"]': {...input},
    '&[type="search"]': {...input},
    '&[type="text"]': {...input},
    '&[type="tel"]': {...input},
    '&[type="url"]': {...input},
    // Input Focus
    '&[type="email"]&:focus': {...inputFocus},
    '&[type="number"]&:focus': {...inputFocus},
    '&[type="search"]&:focus': {...inputFocus},
    '&[type="text"]&:focus': {...inputFocus},
    '&[type="tel"]&:focus': {...inputFocus},
    '&[type="url"]&:focus': {...inputFocus},
    //
  },
  map: {},
  img: {
    width: '100%',
    height: 'auto',
    borderRadius: 6,
  },
  select: {
    menuList: {},
    option: {
      '&:hover, &:focus': {
        backgroundColor: colors.orange,
      },
    },
  },
}

export const light = {
  ...common,
  type: 'activlelight',
  body: () => {
    if (typeof window !== 'undefined') {
      window.document.body.style.color = colors.black
      window.document.body.style.backgroundColor = colors.white
    }
    return {
      ...common.body,
    }
  },
  h1: {
    ...common.h1,
    ...hLight,
  },
  h2: {
    ...common.h2,
    ...hLight,
  },
  h3: {
    ...common.h3,
    ...hLight,
  },
  h4: {
    ...common.h4,
    ...hLight,
  },
  h5: {
    ...common.h5,
    ...hLight,
  },
  h6: {
    ...common.h6,
    ...hLight,
  },
  a: {
    ...common.a,
    color: colors.black,
    borderBottomColor: colors.orange,
  },
  p: {
    ...common.p,
    color: colors.black,
  },
  span: {
    ...common.span,
    color: colors.grey,
  },
  i: {
    ...common.i,
    color: colors.black,
  },
  em: {
    ...common.em,
    color: colors.black,
  },
  strong: {
    ...common.strong,
    color: colors.black,
  },
  hr: {
    ...common.hr,
    borderColor: colors.orange,
    opacity: 0.3,
  },
  input: {
    ...common.input,
  },
  button: {
    '&:hover': {
      ...inputButtonHover,
      color: colors.black,
    },
    '&:focus': {
      ...inputButtonHover,
      color: colors.black,
    },
  },
  map: {
    ...common.map,
  },
  img: {
    ...common.img,
  },
  date: {
    ...common.date,
    color: colors.darkGrey,
  },
  select: {
    menuList: (base, state) => ({
      ...base,
      color: colors.black,
    }),
    option: (base, state) => ({
      ...base,
      ...common.select.option,
      color: colors.black,
      backgroundColor: state.isFocused ? hexToRgba(colors.orange, 0.3) : colors.white,
    }),
  },
}

export const dark = {
  ...common,
  type: 'dark',
  body: () => {
    if (typeof window !== 'undefined') {
      window.document.body.style.color = colors.lightGrey
      window.document.body.style.backgroundColor = colors.black
    }
    return {
      ...common.body,
    }
  },

  h1: {
    ...common.h1,
    ...hDark,
  },
  h2: {
    ...common.h2,
    ...hDark,
  },
  h3: {
    ...common.h3,
    ...hDark,
  },
  h4: {
    ...common.h4,
    ...hDark,
  },
  h5: {
    ...common.h5,
    ...hDark,
  },
  h6: {
    ...common.h6,
    ...hDark,
  },

  a: {
    ...common.a,
    color: colors.orange,
    borderBottomColor: colors.lightGrey,
  },
  p: {
    ...common.p,
    color: colors.lightGrey,
  },
  span: {
    ...common.span,
    color: colors.lightGrey,
  },
  i: {
    ...common.i,
    color: colors.lightGrey,
  },
  em: {
    ...common.em,
    color: colors.lightGrey,
  },
  strong: {
    ...common.strong,
    color: colors.lightGrey,
  },
  hr: {
    ...common.hr,
    borderColor: colors.lightGrey,
    opacity: 0.5,
  },
  input: {
    ...common.input,
    color: colors.lightGrey,
  },
  button: {
    ...common.button,
    color: colors.lightGrey,
    '&:hover': {
      ...inputButtonHover,
      color: colors.orange,
    },
    '&:focus': {
      ...inputButtonHover,
      color: colors.orange,
    },
  },
  map: {
    ...common.map,
  },
  img: {
    ...common.img,
  },
  date: {
    ...common.date,
    color: colors.lightGrey,
  },
  select: {
    control: (base, state) => ({
      ...base,
      ...common.select.control,
      color: colors.lightGrey,
      backgroundColor: colors.black,
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.hasValue ? colors.lightGrey : base.color,
    }),
    menuList: (base, state) => ({
      ...base,
      color: colors.lightGrey,
      backgroundColor: colors.black,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      '&:hover': {
        color: colors.lightGrey,
      },
    }),
    option: (base, state) => ({
      ...base,
      ...common.select.option,
      backgroundColor: state.isFocused ? hexToRgba(colors.orange, 0.3) : colors.black,
    }),
  },
}
