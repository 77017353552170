import React from 'react'
import glamorous from 'glamorous'

import {A} from './common.jsx'

const styleSignedMessage = {
  marginTop: '2rem',
  fontSize: '0.8rem',
  fontWeight: 300,
}

const styleFooter = {
  minHeight: '40px',
  textAlign: 'center',
}

const styleKeybasePubLink = {
  color: 'inherit',
  textDecoration: 'underline',
  border: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
  '&:hover&::before': {
    display: 'none',
  },
}

const Footer = glamorous.footer(styleFooter)
const SignedMessage = (
  <glamorous.Span css={styleSignedMessage}>
    This entire website was cryptographically signed by me, {` `}
    <A
      href="https://keybase.pub/jacobyoung"
      target="_blank"
      rel="noopener noreferrer"
      css={styleKeybasePubLink}
    >
      check it out
    </A>
  </glamorous.Span>
)

export default () => (
  <Footer css={styleFooter}>
    <glamorous.Div>
      <span>&copy;</span>
      {` `}
      {new Date().getFullYear()}
      {` Jacob Young`}
    </glamorous.Div>
  </Footer>
)
