import _ from 'lodash'
import React, {Component} from 'react'
import {node, arrayOf, oneOfType} from 'prop-types'
import {ThemeProvider} from 'glamorous'

import ThemeToggle from '../components/theme-toggle'
import {FullWrapper} from '../components/common'

import {light, dark} from '../css/themes'

const stringToBoolean = str => {
  if (str === 'true') return true
  if (str === 'false') return false
}

export default class ThemeHandler extends Component {
  static propTypes = {
    children: oneOfType([node, arrayOf(node)]).isRequired,
  }
  state = {
    theme: false,
  }

  _persistTheme = () => {
    if (typeof window !== 'undefined' && 'storage' in window) {
      window.storage.setItem('theme', this.state.theme)
    }
  }

  _toggleTheme = () => {
    const newTheme = !this.state.theme
    this.setState({theme: newTheme}, this._persistTheme)
  }

  /*
   * Load theme setting from localstorage
   */
  componentDidMount() {
    if (typeof window !== 'undefined' && 'storage' in window) {
      const theme = stringToBoolean(window.storage.getItem('theme'))
      if (_.isBoolean(theme) && this.state.theme !== theme) {
        /* eslint-disable-next-line react/no-did-mount-set-state */
        this.setState({theme})
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.theme !== prevState.theme) {
      this._persistTheme(this.state.theme)
    }
  }

  render() {
    const theme = this.state.theme ? dark : light
    return (
      <FullWrapper>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <ThemeToggle enabled={this.state.theme} onToggleTheme={this._toggleTheme} />
            {this.props.children}
          </React.Fragment>
        </ThemeProvider>
      </FullWrapper>
    )
  }
}
