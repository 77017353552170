import React from 'react'
import {bool, func} from 'prop-types'
import glamorous from 'glamorous'

import {A} from './common'

const ThemeToggle = ({enabled, onToggleTheme}) => (
  <glamorous.Div
    css={{
      position: 'absolute',
      top: '1rem',
      right: '1.5rem',
    }}
  >
    <A
      href="#"
      css={{
        borderBottom: 'none',
        padding: '0.5rem',
      }}
      onClick={e => {
        e.preventDefault()
        onToggleTheme()
      }}
    >
      <glamorous.I className={`far ${enabled ? 'fa-sun' : 'fa-moon'}`} css={{marginRight: '0.5rem'}} />
      {enabled ? 'Lights On' : 'Lights Off '}
    </A>
  </glamorous.Div>
)
ThemeToggle.propTypes = {
  enabled: bool.isRequired,
  onToggleTheme: func.isRequired,
}

export default ThemeToggle
