import React from 'react'
import {string, shape} from 'prop-types'
import {Link} from 'gatsby'
import glamorous from 'glamorous'

import {colors, fonts, mediaMaxWidth, sm} from '../css/themes'
import {LinkStyled} from './common'

const NavWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: '2rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  '& a': {
    fontFamily: fonts.code,
  },
  [mediaMaxWidth(sm)]: {
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: '2rem',
  },
})

const getPathToRoot = pathname => {
  if (pathname === '/') return pathname

  const depth = pathname.split('/').length - 1
  const rootPath = new Array(depth).fill('../').join('')
  return `$ cd ${rootPath}`
}

const styleNavLink = {
  marginRight: '2rem',
  [mediaMaxWidth(sm)]: {
    marginRight: '0rem',
    marginBottom: '0.5rem',
  },
}

const Nav = ({location}) => (
  <NavWrapper>
    {location &&
      location.pathname !== '/' && (
        <LinkStyled css={styleNavLink}>
          <Link exact={true} to="/" activeStyle={styleActiveLink}>
            {getPathToRoot(location.pathname)}
          </Link>
        </LinkStyled>
      )}

    <LinkStyled css={styleNavLink}>
      <Link to="/writing" activeStyle={styleActiveLink}>
        /writing{' '}
      </Link>
    </LinkStyled>

    <LinkStyled css={styleNavLink}>
      <Link to="/reviews" activeStyle={styleActiveLink}>
        /reviews{' '}
      </Link>
    </LinkStyled>

    <LinkStyled css={styleNavLink}>
      <Link to="/now" activeStyle={styleActiveLink}>
        /now{' '}
      </Link>
    </LinkStyled>

    {/*
    <LinkStyled css={styleNavLink}>
      <Link to="/projects" activeStyle={styleActiveLink}>
        /projects{' '}
      </Link>
    </LinkStyled>
    */}

    <LinkStyled css={styleNavLink}>
      <Link to="/about" activeStyle={styleActiveLink}>
        /about{' '}
      </Link>
    </LinkStyled>
  </NavWrapper>
)

Nav.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
}

const styleActiveLink = {
  borderBottomColor: colors.red,
}

export default Nav
