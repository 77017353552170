import _ from 'lodash'
import React from 'react'
import {string, node, shape} from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

import '@fortawesome/fontawesome-free/css/regular.min.css'
import '@fortawesome/fontawesome-free/css/brands.min.css'
import '@fortawesome/fontawesome-free/css/solid.min.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'

import {Favicons} from '.'
import ThemeHandler from './theme-handler'
import Nav from '../components/nav'
import Footer from '../components/footer'
import {FullWrapper, Body, Container} from '../components/common'

const metaOpenGraph = ({title, url, description}) => {
  return [
    <meta key="og:type" property="og:type" content="website" />,
    <meta key="og:url" property="og:url" content={url} />,
    <meta key="og:title" property="og:title" content={title} />,
    <meta key="og:description" property="og:description" content={description} />,
    // TODO: Pass the post image through the blog-post.jsx template and use it as the OG image
  ]
}

const Default = ({children, title, description, location, data}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            titleFull
            titleShort
            description
            keywords
            siteUrls {
              primary
            }
          }
        }
        allFile(filter: {name: {eq: "avatar"}}) {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {titleFull, titleShort, description: siteDescription, keywords, siteUrls},
      },
    }) => {
      const pageUrl = `${siteUrls.primary}${location.pathname}`
      const pageTitle = title ? `${title} · ${titleShort}` : titleFull
      const pageDescription = description || siteDescription
      return (
        <FullWrapper>
          <ThemeHandler>
            <Body>
              <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={keywords.join(', ')} />
                {/* Open Graph Meta */}
                {metaOpenGraph({title: pageTitle, description: pageDescription, url: pageUrl})}
                {/* Favicon */}
                {_.map(Favicons, favicon => <link key={favicon.href} {...favicon} />)}
              </Helmet>
              <Container>
                {location && location.pathname !== '/' && <Nav location={location} />}
                {children}
              </Container>
            </Body>
            <Footer />
          </ThemeHandler>
        </FullWrapper>
      )
    }}
  />
)

Default.propTypes = {
  children: node.isRequired,
  title: string,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
}

export default Default
