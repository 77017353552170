import React from 'react'
import {string, number} from 'prop-types'
import glamorous from 'glamorous'

import {getThemeStyles} from '../css/themes'
import {relativeDate} from '../utils'

/*
 * Standard HTML Elements
 */
export const FullWrapper = glamorous.div({display: 'flex', height: '100%', flex: 1, flexDirection: 'column'})
export const Body = glamorous.div({}, getThemeStyles('body'))
export const H1 = glamorous.h1({}, getThemeStyles('h1'))
export const H2 = glamorous.h2({}, getThemeStyles('h2'))
export const H3 = glamorous.h3({}, getThemeStyles('h3'))
export const H4 = glamorous.h4({}, getThemeStyles('h4'))
export const H5 = glamorous.h5({}, getThemeStyles('h5'))
export const H6 = glamorous.h6({}, getThemeStyles('h6'))
export const A = glamorous.a({}, getThemeStyles('a'))
export const P = glamorous.p({}, getThemeStyles('p'))
export const Span = glamorous.span({}, getThemeStyles('span'))
export const I = glamorous.i({}, getThemeStyles('i'))
export const EM = glamorous.em({}, getThemeStyles('em'))
export const Strong = glamorous.strong({}, getThemeStyles('strong'))
export const HR = glamorous.hr({}, getThemeStyles('hr'))
export const UL = glamorous.ul({}, getThemeStyles('ul'))
export const OL = glamorous.ol({}, getThemeStyles('ol'))
export const DL = glamorous.dl({}, getThemeStyles('dl'))
export const LI = glamorous.li({}, getThemeStyles('li'))

/*
 * Container
 */
export const Container = glamorous.div({}, getThemeStyles('container'))

/*
 * Allows for theme switching for <Link> components
 */
export const LinkStyled = glamorous.span({}, ({theme}) => ({'& a': {...theme.a}}))

/*
 * Avatar
 */
const AvatarWrapper = glamorous.img(
  {
    width: 100,
    height: 100,
    borderRadius: '100%',
  },
  getThemeStyles('avatar', props => ({
    width: props.size,
    height: props.size,
  }))
)
export const Avatar = ({src, ...rest}) => <AvatarWrapper src={src} alt="Profile Picture" {...rest} />
Avatar.propTypes = {
  src: string.isRequired,
  size: number.isRequired,
}

/*
 * Date
 */

const DateStyled = glamorous.i({}, getThemeStyles('date'))
export const Date = ({dateString, color}) => (
  <glamorous.Div>
    <DateStyled>{relativeDate(dateString)}</DateStyled>
  </glamorous.Div>
)

Date.propTypes = {
  dateString: string.isRequired,
}
